import styled from 'styled-components';
import tw from 'twin.macro';
import MEDIA from '../../helpers/mediaTemplates';

export const Container = styled.footer`
  ${tw`flex text-center h-20 flex-row justify-between items-center px-4 mt-8`}
  background-color: #c4ddf2;
  p {
    ${tw`px-4`}
  }
  a {
    &:hover {
      ${tw`text-gray-500`}
    }
  }

  ${MEDIA.TABLET` 
    ${tw`text-sm flex-col`}
  `}
`;
