import React from 'react';
import { Container } from './footer.css';

const Footer = () => {
  return (
    <Container>
      <p>
        Phone | <a href="tel:+447876571990">+44 7876 571 990</a>
      </p>
      <p>
        Email | <a href="mailto:moonbeamssleep@gmail.com">MoonbeamsSleep@gmail.com</a>
      </p>
      <p>Website made by Warren Perkins</p>
    </Container>
  );
};

export default Footer;
